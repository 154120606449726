<template>
  <div>
    <div class="list1">
      <div class="item">
        <div class="text">总投资额</div>
        <div class="value">xxx</div>
      </div>
      <div class="item">
        <div class="text">总收益</div>
        <div class="value">xxxx</div>
      </div>
      <div class="item">
        <div class="text">已实现盈亏</div>
        <div class="value">xxx</div>
      </div>
      <div class="item">
        <div class="text">未实现盈亏</div>
        <div class="value">xxx</div>
      </div>
      <br/>
      <div class="item">
        <div class="text">保证金模式</div>
        <div class="value">xxx</div>
      </div>
      <div class="item">
        <div class="text">初始杠杆</div>
        <div class="value">xxx</div>
      </div>
      <div class="item">
        <div class="text">初始保证金</div>
        <div class="value">xxx</div>
      </div>
      <div class="item">
        <div class="text">网格数量</div>
        <div class="value">xxx</div>
      </div>
      <div class="item">
        <div class="text">每笔数量</div>
        <div class="value">xxx</div>
      </div>
      <div class="item">
        <div class="text">价格范围</div>
        <div class="value">xxx</div>
      </div>
      <div class="item">
        <div class="text">每格利润</div>
        <div class="value">xxx</div>
      </div>

    </div>
    <div class="senior">
      <p>高级（可选）</p>
      <van-form label-width="160px">
        <!-- 通过 pattern 进行正则校验 -->
        <van-field
            readonly
            name="picker"
            :value="form.exchange_name"
            label="网格触发"
            placeholder="标记"
            right-icon="arrow-down"
            @click="handlerShowPicker('exchange_name')"
        />
        <van-field v-model="value" placeholder="触发价格" />
        <van-field
            readonly
            name="picker"
            :value="form.exchange_name"
            label="终止触发"
            placeholder="标记"
            right-icon="arrow-down"
            @click="handlerShowPicker('exchange_name')"
        />
        <div class="button" style="margin-top: 16px">
          <van-button  :loading="loading" style="background-color:#696969;">终止</van-button>
          <van-button  :loading="loading"  style="background-color:#FFD700;">确定</van-button>
        </div>
      </van-form>
    </div>

  </div>
</template>

<script>
import {Notify,Toast} from "vant";
import {getAccounts} from "../../../api/setting";
export default {
  name: "grid_detail",
  data(){
    return {
      value:'',
      loading: false,
      form: {
        exchange_name: "",
        primary_account_id: "",
        sub_account_id: "",
        sub_account_name: '',
        currency: '',

        account_type: "",
        rest: "",
        count: 0,
      },
    }
  },
  mounted() {
    this.handlerGetAccountList()
  },
  methods:{
    handlerShowPicker(e) {
      // console.log(e);
      this.checked_field = e;
      if(e === 'exchange_name'){
        this.columns = this.exchange_names;

      }
      if(e === 'primary_account_id'){
        if(!this.form.exchange_name){
          Notify({message:'请先选择交易所',type:'warning'})
          return
        }
        this.checked_list = this.accounts.filter(item => item.exchange_name === this.form.exchange_name);
        this.columns = this.checked_list.map(item => item.account_name);
      }
      if(e === 'sub_account_id'){
        if(!this.form.primary_account_id) {
          Notify({message: '请先选择交易所', type: 'warning'})
          return
        }
        const item = this.accounts.find(item => item.id === this.form.primary_account_id)
        this.checked_list = item.children;
        this.columns = this.checked_list.map(item => item.account_name);
      }
      if(e === 'account_type'){
        this.checked_list = this.account_types
        this.columns = this.account_type_names
      }
      this.showPicker = true;
    },
    handlerGetAccountList(){
      const t = Toast.loading({
        message:'加载中',
        forbidClick: true,
        duration: 10000,
      })
      getAccounts().then(res => {
        this.accounts = res.data;
        this.exchange_names =  Array.from(new Set(res.data.map(item => item.exchange_name)))
        t.clear()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.list1{
  padding: 20px 0;
  .item{
    display: flex;
    justify-content: space-between;
    color: #8b8a8a;
    padding: 3px 5px;
    .value{
      color: lightgray;
    }
  }
}
.senior{
  .van-form{
    background-color: #000;
    .button{
      display: flex;
      //background-color: pink;
      justify-content: space-evenly;
      .van-button{
        display: block;
        width: 44%;
        text-align: center;
        border: none;
      }
    }

  }
}
</style>
